import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundPageComponent } from "src/app/ui/pages/not-found-page/not-found-page.component";
import { OzwaDocComponent } from "src/app/ui/pages/ozwa-doc/ozwa-doc.component";
import { AuthGuard } from "../gaurds/auth.guard";
import { RoleGuard } from "../gaurds/role.guard";

const routes: Routes = [
  //here will be the root routes for all the modules for achiveing lazy loading.
  { path: "", redirectTo: "user", pathMatch: "full" },
  {
    path: "user",
    loadChildren: () => import("../../auth/auth.module").then((m) => m.AuthModule),
    data: { isNavbarVisible: false },
    canActivate: [AuthGuard],
  },
  {
    path: "conciliator",
    loadChildren: () => import("../../roles/conciliator/conciliator.module").then((m) => m.ConciliatorModule),
    data: { isNavbarVisible: true },
    canLoad: [RoleGuard],
  },
  {
    path: "supervisor",
    loadChildren: () => import("../../roles/supervisor/supervisor.module").then((m) => m.SupervisorModule),
    data: { isNavbarVisible: true },
    canLoad: [RoleGuard],
  },
  {
    path: "lawyer",
    loadChildren: () => import("../../roles/lawyer/lawyer.module").then((m) => m.LawyerModule),
    data: { isNavbarVisible: true },
    canLoad: [RoleGuard],
  },
  {
    path: "call-center",
    loadChildren: () => import("../../roles/call-center/call-center.module").then((m) => m.CallCenterModule),
    data: { isNavbarVisible: true },
    canLoad: [RoleGuard],
  },

  {
    path: "info/:data",
    component: OzwaDocComponent,
    data: { isNavbarVisible: true },
    canLoad: [RoleGuard],
  },

  {
    path: "**",
    component: NotFoundPageComponent,
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
