import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { catchError, Observable, tap, throwError } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslatePipe } from "../pipes/translate.pipe";
import { CompleteProfileDialogComponent } from "src/app/shared/dialogs/complete-profile-dialog/complete-profile-dialog.component";
import { UserService } from "@kortobaa-front/authentication";
import { environment } from "src/environments/environment";

@Injectable()
export class GlobalErrorHandler implements HttpInterceptor {
  constructor(
    private _snackBar: MatSnackBar,
    private _translatPipe: TranslatePipe,
    private _router: Router,
    private _dialog: MatDialog,
    private _user: UserService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: (httpError) => {
          //destruct statuscode & error message
          const { statusCode, message } = httpError?.error?.error;
          switch (statusCode) {
            //case of b=not complete profile
            case 423:
              const dialogRef = this._dialog.open(CompleteProfileDialogComponent, {
                panelClass: "white-dialog",
                width: "58rem",
                height: "67rem",
                data: { route: "" },
              });
              break;
            // case of wrong entity
            case 422:
              this._snackBar.open("عذرا حدث خطأ ما...", "X", {
                panelClass: "error-snackbar",
                // verticalPosition: "bottom",
                // duration: 2000,
              });
              break;
            //case of unauthorized access
            case 401:
              const snackbar = this._snackBar.open("أنت غير مصرح بذلك اعد تسجيل الدخول من فضلك.", "X", {
                panelClass: "error-snackbar",
                // verticalPosition: "bottom",
                // duration: 2000,
              });
              throw httpError;
              break;
            //in case of unknown errors ... comes with custom message that get translated
            case 400:
              this._snackBar.open(this._translatPipe.transform(message) as string, "X", {
                panelClass: "error-snackbar",
                // verticalPosition: "bottom",
                // duration: 2000,
              });
              break;
            // in case server side error
            case 500:
              this._snackBar.open("عذرا وقع خطأ بالسيرفر...", "X", {
                panelClass: "error-snackbar",
                // verticalPosition: "bottom",
                // duration: 2000,
              });
              break;
            case 404:
              if (
                !request.url.includes(`lawyer/subscription-payments`) &&
                !request.url.includes(`supervisor/subscription-payments`)
              ) {
                this._snackBar.open("عذرا هذا الطلب لم يتم العثور عليه ...", "X", {
                  panelClass: "error-snackbar",
                  // verticalPosition: "bottom",
                  // duration: 2000,
                });
              }
              break;
            default:
              throw httpError;
          }
        },
      })
    );
  }
}
