export const translation: any = {
  "User already has a draft of this type.": "لديك بالفعل مسودة محفوظة من هذه القضية",
  "User already has a case of this type.": "لديك بالفعل قضية من هذا النوع",
  "User already has an active inquiry.": "لديك بالفعل استفسار جارى  ",
  "Invalid OTP or login data": "هذا الرمز غير صحيح",
  "User token not found.": "هذا الرمز غير صحيح",
  "email dosn't exist.": "هذا البريد الالكترونى غير مسجل",
  "Email already exist.": "هذاالبريد الالكترونى مسجل بالفعل",
  "User already have a cancellation request": "لقد قدمت طلب تنازل مسبقًا",
  "User already have a complain complain": "لقد قدمت شكوى لهذه القضية مسبقًا",
  "One or some of the assignees's id's in not valid":
    "بعض من هؤلاء ارقام الهوية للحامين/ المصلحين غير صالحين... رجاء حاول مرة اخرى.",
  "Cannot assigne lawyers to closed or request Usercase": "لا يمكن إسناد محامين إلى قضية منتهية",
  "UserCase is already assigneed to lawyer.": "القضية مسندة إلى محامى بالفعل",
  "There is no usercase request with this id.": "لا يوجد قضية بهذا الرقم",
  "Inquiry is already assigneed to lawyer.": "الاستفسار مسند بالفعل إلى محامى",
  "There isn't supervisor yet!": "لا يوجد مشرفين حتى الأن",
  "Maximum number of attached items is 10 files 2Mb each.":
    "اقصى عدد للمفات المرفقة هو عشر ملفات بسماحة لا تزيد عن 2 ميجابايت لكل واحد",
  "File size is too large. file size should be less than 2Mb":
    "مساحة هذا الملف كبير. يجب ان لا تزيد مساحته عن 2 ميجابايت",
  "cannot response to Usercase request to modfiy by user": "لا يمكن الرد على قضية بحاجة للتعديل",
  "UserCase is already assigneed to conciliator.": "القضية مسندة إلى مصلح بالفعل",
  "Email already exists!": "هذا البريد الالكترونى موجود بالفعل",
  "Phone already exists!": "هذا الجوال موجود بالفعل",
  "National Id already exists!": "هذا الرقم القومى موجود بالفعل",
  "Case with these id is not found.": "قضية بهذا الرقم غير موجودة بالفعل",
  "Cannot respond to closed inquiry.": "لا يمكنك الرد على استفسار مغلق بالفعل",
  "OTP has been expired": "هذا الرمز قد انتهت صلاحيته",
  "Subscription remaining payment status is requested": "لقد تم طلب دفع باقي التكلفة بالفعل",
  "Subscription upgrade service status is requested": "لقد تم طلب ترقية الخدمة بالفعل",
  "User doesn't paid the full price": "لم يقم المستفيد بدفع المبلغ المتبقي للخدمة بعد",
};
