import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private _auth: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // if user is logged in then redirect from auth module

    if (state.url == "/user/my-profile") {
      return true;
    } else if (!this._auth.user.currentUser) {
      return true;
    } else {
      const currentMainRoute = this.currentRoute(this._auth.user.currentUser.roles[0]) as string;
      this._router.navigateByUrl(currentMainRoute);
      return false;
    }
  }

  currentRoute(role: string) {
    switch (role) {
      case "supervisors":
        return "/supervisor";
      case "lawyers":
        return "/lawyer";
      case "conciliators":
        return "/conciliator";
      case "callcenter":
        return "/call-center";
      default:
        return null;
    }
  }
}
