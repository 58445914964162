import { Injectable } from "@angular/core";
import { CanLoad, Route, Router, UrlSegment, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class RoleGuard implements CanLoad {
  constructor(private _router: Router, private _auth: AuthService) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //if user is not logged in
    //then route to login component
    if (this._auth.user.currentUser) {
      //if user logged in
      //get the main route for current role
      const roleHomeRoute = this.currentRoute(this._auth.user.currentUser.roles[0]);
      const currentRoute = "/" + segments[0].path;
      //if it's match return true to prevent lopping
      if (roleHomeRoute == currentRoute || roleHomeRoute == null) return true;
      //if other role then route to current role main route
      this._router.navigateByUrl(roleHomeRoute);
      return true;
    }
    this._router.navigateByUrl("/user");
    return false;
  }

  currentRoute(role: string) {
    switch (role) {
      case "supervisors":
        return "/supervisor";
      case "lawyers":
        return "/lawyer";
      case "conciliators":
        return "/conciliator";
      case "callcenter":
        return "/call-center";
      default:
        return null;
    }
  }
}
