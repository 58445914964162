<div class="notification" id="openNotify" #openNotify>
  <div class="notification__wrapper">
    <div class="notification__wrapper__header">
      <div class="d-flex">
        <mat-icon
          (click)="close()"
          aria-hidden="false"
          aria-label="close icon"
          class="material-icons-outlined close"
          fontIcon="close"
          >close</mat-icon
        >
        <h4>الاشعارات</h4>
      </div>
      <div>
        <div class="d-flex">
          <p>عرض الغير مقروءة فقط</p>
          <mat-icon
            [ngStyle]="{ display: toggleOn ? 'none' : 'block' }"
            aria-hidden="false"
            aria-label="toggle off icon"
            class="material-icons-outlined toggle toggle_off"
            fontIcon="toggle_off"
            (click)="toggle(true)"
            >toggle_off</mat-icon
          >
          <mat-icon
            [ngStyle]="{ display: !toggleOn ? 'none' : 'block' }"
            aria-hidden="false"
            aria-label="toggle on icon"
            class="material-icons-outlined toggle toggle_on"
            fontIcon="toggle_on"
            (click)="toggle(false)"
            >toggle_on</mat-icon
          >
        </div>
      </div>
    </div>
    <div class="notification__wrapper__content">
      <div class="notification__wrapper__content_wrapper">
        <h6>الاحدث</h6>
        <div
          *ngFor="let notification of notifications; let i = index"
          class="notification__wrapper__content_wrapper__items"
        >
          <div
            [ngClass]="{ seen: notification.seen }"
            [ngStyle]="{
              cursor: notification?.notificationType ? 'pointer' : 'default'
            }"
            class="notification__wrapper__content_wrapper__items-item"
            (click)="
              notification?.notificationType
                ? goTo(notification.notificationType, notification.operationId, notification.id)
                : close()
            "
          >
            <p>{{ notification.message }}</p>
            <div *ngIf="!notification.seen">
              <i></i>
            </div>
          </div>
          <mat-divider *ngIf="notifications.length - (i + 2) >= 0"></mat-divider>
        </div>
        <div
          *ngIf="hasMoreNotifications"
          class="notification__wrapper__content_wrapper__load-more"
          (click)="loadMore()"
        >
          المزيد
        </div>
        <div *ngIf="!hasMoreNotifications" class="notification__wrapper__content_wrapper__no-more">لا يوجد المزيد</div>
      </div>
    </div>
  </div>
</div>
