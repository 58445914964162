<mat-toolbar *ngIf="navbarService.isNavbarVisible" class="navbar">
  <div class="menu">
    <!-- navbar logo  -->
    <div class="menu__logo">
      <div class="img" (click)="redirectToHomePage()">
        <img src="../../../../assets/images/logo.png" alt="logo" />
      </div>
      <div class="sperator"></div>
    </div>
    <!-- navbar links  -->
    <div class="menu__links" #overlay>
      <div class="menu__links_nav" #nav>
        <div class="menu__links_nav_header" *ngIf="this.navResponsive == true">
          <mat-icon
            (click)="makeItResponsive()"
            aria-hidden="false"
            aria-label="Example close icon"
            class="material-icons-outlined"
            >close</mat-icon
          >
          <div class="img" (click)="redirectToHomePage()">
            <img src="../../../../assets/images/logoo.png" alt="logo" />
          </div>
        </div>
        <a
          class="link"
          *ngIf="auth.user.currentUser && this.navResponsive == true"
          routerLink="/user/my-profile"
          (click)="makeItResponsive()"
          >{{ auth.user.currentUser.username }}</a
        >
        <a
          class="link"
          *ngIf="!auth.user.currentUser && this.navResponsive == true"
          [routerLink]="['/user/login']"
          (click)="makeItResponsive()"
          >تسجيل دخول</a
        >
        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'conciliators'"
          routerLink="/conciliator/cases"
          (click)="makeItResponsive()"
          routerLinkActive="active"
          ><div>طلبات الصلح</div></a
        >
        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'supervisors'"
          routerLink="/supervisor/cases"
          (click)="makeItResponsive()"
          routerLinkActive="active"
          ><div>القضايا</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'supervisors'"
          routerLink="/supervisor/inquiries"
          (click)="makeItResponsive()"
          routerLinkActive="active"
          ><div>الاستشارات</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'supervisors'"
          routerLink="/supervisor/complains"
          (click)="makeItResponsive()"
          routerLinkActive="active"
          ><div>الشكاوي</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'supervisors'"
          routerLink="/supervisor/previous-cases"
          (click)="makeItResponsive()"
          routerLinkActive="active"
          ><div>القضايا السابقة</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'lawyers'"
          routerLink="/lawyer/cases"
          (click)="makeItResponsive()"
          routerLinkActive="active"
          ><div>قضايا الترافع</div></a
        >
        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'lawyers'"
          routerLink="/lawyer/inquiries"
          routerLinkActive="active"
          (click)="makeItResponsive()"
          ><div>الاستشارات</div></a
        >
        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'lawyers'"
          routerLink="/lawyer/previous-cases"
          (click)="makeItResponsive()"
          routerLinkActive="active"
          ><div>القضايا السابقة</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'lawyers'"
          routerLink="/lawyer/reports"
          (click)="makeItResponsive()"
          routerLinkActive="active"
          ><div>التقارير</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'callcenter'"
          (click)="makeItResponsive()"
          routerLink="/call-center/my-applications"
          ><div>الرئيسية</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'callcenter'"
          (click)="makeItResponsive()"
          (click)="openCreateCaseDialog()"
          ><div>تقديم قضية</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'callcenter'"
          (click)="makeItResponsive()"
          routerLink="/call-center/create-inquiry"
          ><div>تقديم استشارة</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'callcenter'"
          (click)="makeItResponsive()"
          routerLink="/call-center/search-applications"
          ><div>استعلام</div></a
        >
        <div
          class="link logout"
          *ngIf="auth.user.currentUser && this.navResponsive == true"
          (click)="openLogoutDialog()"
        >
          <mat-icon class="material-icons-outlined" aria-hidden="false" aria-label="logout icon" fontIcon="logout"
            >logout</mat-icon
          >
          <p>تسجيل الخروج</p>
        </div>

        <figure class="menu__links_img" *ngIf="this.navResponsive == true">
          <img [src]="profileImg" alt="الملف الشخصي" />
        </figure>
      </div>

      <div class="responsive" #navResp>
        <mat-icon id="menu" class="material-icons-outlined" (click)="makeItResponsive()"> menu </mat-icon>
        <div class="img" (click)="redirectToHomePage()">
          <img src="../../../../assets/images/logoo.png" alt="logo" />
        </div>
        <div class="responsive_icon">
          <mat-icon class="material-icons-outlined" *ngIf="auth.user.currentUser" routerLink="/user/my-profile">
            account_circle
          </mat-icon>
          <mat-icon
            *ngIf="!auth.user.currentUser"
            [routerLink]="['/user/login']"
            (click)="makeItResponsive()"
            class="material-icons-outlined"
            >logout</mat-icon
          >
          <mat-icon
            *ngIf="auth.user.currentUser"
            id="notify"
            class="material-icons-outlined"
            (click)="openNotification(this.navFlag)"
          >
            notifications
          </mat-icon>
          <div class="icon-counter" *ngIf="hasUnseenNotification > 0"></div>

          <!-- <div class="icon-wrapper">
            <mat-icon id="notify" class="material-icons-outlined" (click)="openNotification(this.navFlag)">
              notifications
            </mat-icon>
          </div> -->
        </div>
      </div>
    </div>
    <!-- navbar user toolset -->
    <div class="menu__user">
      <a *ngIf="!auth.user.currentUser" [routerLink]="['/user/login']">تسجيل دخول</a>
      <a *ngIf="auth.user.currentUser" routerLink="/user/my-profile">{{ auth.user.currentUser.username }}</a>
      <div class="icon-wrapper">
        <mat-icon id="notify" class="material-icons-outlined" (click)="openNotification(this.navFlag)">
          notifications
        </mat-icon>
        <div class="icon-counter" *ngIf="hasUnseenNotification > 0">{{ hasUnseenNotification }}</div>
      </div>
      <div
        matTooltip="تسجيل الخروج"
        matTooltipPosition="above"
        matTooltipClass="tooltip-grey"
        mat-button
        class="icon-wrapper nav-logout"
        *ngIf="auth.user.currentUser"
        (click)="openLogoutDialog()"
      >
        <mat-icon class="material-icons-outlined" aria-hidden="false" aria-label="logout icon" fontIcon="logout"
          >logout</mat-icon
        >
      </div>
      <!-- <div class="disabled-feature">تواصل معانا</div> -->
    </div>
  </div>
</mat-toolbar>
