<div class="logout-dialog">
  <h2 class="logout-dialog__title">تسجيل الخروج</h2>
  <mat-icon aria-hidden="false" aria-label="Example login icon" class="material-icons-outlined logout-dialog__icon"
    >logout</mat-icon
  >
  <p class="logout-dialog__message">هل أنت متأكد من تسجيل الخروج؟</p>
  <div class="logout-dialog__buttons" mat-dialog-actions class="button">
    <button mat-button mat-dialog-close class="logout-dialog__buttons__register">لا</button>
    <button mat-button (click)="logout()" class="logout-dialog__buttons__login">نعم</button>
    <mat-icon
      mat-dialog-close
      aria-hidden="false"
      aria-label="Example close icon"
      class="material-icons-outlined logout-dialog__buttons__close"
      >close</mat-icon
    >
  </div>
</div>
