import { Component, OnInit } from "@angular/core";
import { NavbarService } from "../../service/navbar.service";

@Component({
  selector: "app-not-found-page",
  templateUrl: "./not-found-page.component.html",
  styleUrls: ["./not-found-page.component.scss"],
})
export class NotFoundPageComponent implements OnInit {
  constructor(private _navbar: NavbarService) {
    this._navbar.setNavbarVisiabilty(false);
  }

  ngOnInit(): void {}
}
