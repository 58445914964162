<div class="contact-us-dialog">
  <!-- first screen for entering a reason  -->
  <!-- contact-us dialog title  -->
  <h2 class="contact-us-dialog__title">تواصل معنا</h2>
  <!-- contact-us dialog content  -->
  <div class="contact-us-dialog__form">
    <form #contactUsForm="ngForm" (ngSubmit)="contactUsSubmit(contactUsForm)">
      <!-- contact-us dialog input  -->

      <!-- first name  -->
      <div class="field field-string">
        <label class="field__label">
          الاسم الاول
          <span class="field__label__required">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            class="warning-border"
            matInput
            ngModel
            name="userName"
            [(ngModel)]="this.userObj.username"
            required
            #userName="ngModel"
            pattern="^[a-zA-Z\s\u0600-\u06ff]+$"
            onkeydown="return /[a-zA-Z\s\u0600-\u06ff]/i.test(event.key)"
            maxLength="50"
            minlength="2"
          />
        </mat-form-field>
        <small
          class="field__alert-message"
          *ngIf="!userName?.valid && userName.touched && userName.errors && userName.errors['required']"
          >هذا الحقل مطلوب</small
        >
        <small class="field__alert-message" *ngIf="userName.errors && userName.touched && userName.errors['pattern']"
          >لا يمكن ادخال حروف مميزة او ارقام</small
        >
      </div>
      <!-- Email -->
      <div class="field field-string">
        <label class="field__label">
          البريد الالكترونى
          <span class="field__label__required">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            class="warning-border"
            matInput
            ngModel
            name="email"
            [(ngModel)]="this.userObj.email"
            required
            #email="ngModel"
            pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
            maxLength="50"
            minlength="2"
          />
        </mat-form-field>
        <small
          class="field__alert-message"
          *ngIf="!email?.valid && email.touched && email.errors && email.errors['required']"
          >هذا الحقل مطلوب</small
        >
        <small class="field__alert-message" *ngIf="email.errors && email.touched && email.errors['pattern']"
          >الرجاء إدخال عنوان البريد الإلكتروني الخاص بك في شكل yourname@example.com
        </small>
      </div>
      <!-- phone number -->
      <div class="field field-number">
        <label class="field__label">
          رقم الجوال
          <span class="field__label__required">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            type="text"
            [(ngModel)]="this.userObj.phone"
            ngModel
            required
            name="phone"
            #phone="ngModel"
            pattern="^966[0-9]+$"
            onkeypress="return /[0-9]/i.test(event.key)"
            maxlength="10"
          />
        </mat-form-field>
        <small
          class="field__alert-message"
          *ngIf="!phone?.valid && phone.touched && phone.errors && phone.errors['required']"
          >هذا الحقل مطلوب</small
        >
        <small class="field__alert-message" *ngIf="phone.errors && phone.touched && phone.errors['pattern']"
          >الرجاء إدخال رقم الجوال الخاص بك على أن يبدأ بكود 966</small
        >
      </div>
      <!-- message  -->
      <div class="field field-string textarea">
        <label class="field__label">
          الرسالة
          <span class="field__label__required">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            class="warning-border"
            matInput
            ngModel
            name="message"
            required
            [(ngModel)]="description"
            #message="ngModel"
            maxlength="1000"
            (keypress)="validComment(message)"
            (change)="validComment(message)"
            (input)="validComment(message)"
            (paste)="validComment(message)"
            pattern="[/\n/\sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]{1,1000}$"
          ></textarea>
        </mat-form-field>
        <small class="field__alert-message" *ngIf="!message?.valid && message.touched && message.errors"
          >هذا الحقل مطلوب</small
        >
      </div>
      <!-- contact-us dialog buttons  -->
      <div class="contact-us-dialog__buttons" mat-dialog-actions>
        <!-- submit request button  -->
        <button
          type="submit"
          mat-button
          class="contact-us-dialog__buttons__submit"
          [disabled]="contactUsForm.status == 'INVALID' || description.length == 0"
          [style.opacity]="contactUsForm.status == 'INVALID' || description.length == 0 ? 0.5 : 1"
        >
          تأكيد
        </button>
        <!-- close button  -->
        <mat-icon
          mat-dialog-close
          aria-hidden="false"
          aria-label="Example close icon"
          class="material-icons-outlined contact-us-dialog__buttons__close"
          >close</mat-icon
        >
      </div>
    </form>
  </div>
</div>
