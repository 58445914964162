import { Pipe, PipeTransform } from "@angular/core";
import { translation } from "./manifesto";

@Pipe({
  name: "translate",
})
export class TranslatePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    return translation[value] || value;
  }
}
