import { FooterService } from "./../../service/footer.service";
import { Subscription } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { NavbarService } from "../../service/navbar.service";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/core/services/auth.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-ozwa-doc",
  templateUrl: "./ozwa-doc.component.html",
  styleUrls: ["./ozwa-doc.component.scss"],
})
export class OzwaDocComponent implements OnInit {
  docSub$!: Subscription;
  contentTitle: string = "";
  profileImg!: string;
  public screenWidth: any = window.innerWidth;

  constructor(
    private _nav: NavbarService,
    private _route: ActivatedRoute,
    private _footer: FooterService,
    public auth: AuthService,
    private _location: Location
  ) {
    if (this.auth?.user?.currentUser?.roles[0]) this._nav.setNavbarVisiabilty(true);

    this._footer.setFooterVisibility(true);

    // get data of filter from param
    this.docSub$ = this._route.params.subscribe((res: any) => {
      this.contentTitle = res.data;
    });
    this.profileImg = this.auth?.user?.currentUser?.roles[0]
      ? `../../../../assets/images/${this.auth?.user?.currentUser?.roles[0]}-profile.svg`
      : `../../../../assets/images/supervisors-profile.svg`;

    if (this.screenWidth <= 768 || !this.auth.currentUser) {
      this._nav.setNavbarVisiabilty(false);
    }
  }

  ngOnInit(): void {}

  // go to back
  back() {
    this._location.back();
  }

  ngOnDestroy(): void {
    if (this.docSub$) this.docSub$.unsubscribe();
  }
}
