import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserService } from "@kortobaa-front/authentication";
import { Observable } from "rxjs";
import { CaseType, Section } from "src/app/core/interfaces/case-type.interface";
import { AuthService } from "src/app/core/services/auth.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CaseTypeService {
  constructor(private _http: HttpClient, private _auth: AuthService, private _user: UserService) {}

  userToken() {
    return {
      headers: { Authorization: `Bearer ${this._user.getToken()}` },
    };
  }

  //get all case type (doesn't require user token)
  getCaseType(): Observable<CaseType[]> {
    return this._http.get<CaseType[]>(`${environment.api_url}/user/cases`);
  }

  //get all section of a certain case by id (doesn't require user token)
  getCaseDetails(id: number): Observable<Section[]> {
    return this._http.get<Section[]>(`${environment.api_url}user/cases/${id}/sections`);
  }

  //create an case
  createCase(newCase: any): Observable<any> {
    return this._http.post(`${environment.api_url}user/user-cases`, newCase, this.userToken());
  }

  //save a draft
  createDraft(draft: any): Observable<any> {
    return this._http.post(`${environment.api_url}user/drafts`, draft, this.userToken());
  }

  //save a draft
  updateDraft(draft: any, id: number): Observable<any> {
    return this._http.patch(`${environment.api_url}user/drafts/${id}`, draft, this.userToken());
  }

  getDraftByCaseId(id: number) {
    return this._http.get(`${environment.api_url}user/my-drafts?filter[where][case_id]=${id}`, this.userToken());
  }

  //Check whether user has opening case of that or not
  checkUserHasCase(id: number): Observable<any> {
    return this._http.get(`${environment.api_url}user/user-has-case/${id}`, this.userToken());
  }
}
