import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslatePipe } from "./pipes/translate.pipe";
import { AuthGuard } from "./gaurds/auth.guard";
import { AuthService } from "./services/auth.service";
import { GlobalErrorHandler } from "./error-handler/global-error-handler.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [
    TranslatePipe,
    AuthGuard,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: GlobalErrorHandler, multi: true },
  ],
})
export class CoreModule {}
