import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { CaseType } from "src/app/core/interfaces/case-type.interface";
import { TranslatePipe } from "src/app/core/pipes/translate.pipe";
import { AuthService } from "src/app/core/services/auth.service";
import { CompleteProfileDialogComponent } from "src/app/shared/dialogs/complete-profile-dialog/complete-profile-dialog.component";
import { CaseTypeService } from "../../services/case-type.service";
import { WarningDialogComponent } from "../warning-dialog/warning-dialog.component";

@Component({
  selector: "app-create-case",
  templateUrl: "./create-case.component.html",
  styleUrls: ["./create-case.component.scss"],
})
export class CreateCaseComponent {
  cases: CaseType[] | null = null;
  caseTypeSub!: Subscription;
  checkHasCaseSub!: Subscription;
  constructor(
    private _caseTypeService: CaseTypeService,
    private _router: Router,
    private _dialogRef: MatDialogRef<CreateCaseComponent>
  ) {
    //get all cases
    this.caseTypeSub = this._caseTypeService.getCaseType().subscribe((data) => {
      this.cases = data;
    });
  }

  //to redirect to case and check if user has open case of that type
  goToCase(chooseCaseForm: NgForm) {
    this._dialogRef.close();
    this._router.navigate(["/call-center/create-usercase", chooseCaseForm.form.value.caseType.id]);
  }

  ngOnDestroy(): void {
    if (this.caseTypeSub) this.caseTypeSub.unsubscribe();
    if (this.checkHasCaseSub) this.checkHasCaseSub.unsubscribe();
  }
}
