import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/services/auth.service";
import { FirebaseService } from "src/app/core/services/firebase.service";

@Component({
  selector: "app-logout-dialog",
  templateUrl: "./logout-dialog.component.html",
  styleUrls: ["./logout-dialog.component.scss"],
})
export class LogoutDialogComponent implements OnInit {
  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _dialogRef: MatDialogRef<LogoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _firebase:FirebaseService
  ) {}

  ngOnInit(): void {}

  //logout
  logout() {
    this._firebase.logout().subscribe()
    this._auth.user.logout();
    this._router.navigateByUrl("/user/login");
    this._dialogRef.close();
  }
}
