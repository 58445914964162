import { FooterService } from "./../../service/footer.service";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/services/auth.service";
import { ContactUsDialogComponent } from "../../dialog/contact-us-dialog/contact-us-dialog.component";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _dialog: MatDialog,
    public _footer: FooterService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {}

  redirectToHomePage() {
    if (this.auth?.user?.currentUser?.roles[0]) {
      const role = this._auth.user.currentUser.roles[0];
      switch (role) {
        case "supervisors":
          this._router.navigateByUrl("/supervisor");
          break;
        case "lawyers":
          this._router.navigateByUrl("/lawyer");
          break;
        case "conciliators":
          this._router.navigateByUrl("/conciliator");
          break;
        case "callcenter":
          this._router.navigateByUrl("/call-center");
          break;
        default:
          this._router.navigateByUrl("/");
          break;
      }
    } else {
      this._router.navigateByUrl("/");
    }
  }

  //open contact us dialog
  openContactUsDialog(): void {
    this._dialog.open(ContactUsDialogComponent, {
      minHeight: "60rem",
      width: "64rem",
      autoFocus: false,
      data: {},
    });
  }
}
