<div class="check-login-dialog">
  <h2 class="check-login-dialog__title">تسجيل الدخول</h2>
  <mat-icon aria-hidden="false" aria-label="Example login icon" class="material-icons-outlined check-login-dialog__icon"
    >login</mat-icon
  >
  <p class="check-login-dialog__message">{{ data.message }}</p>
  <div class="check-login-dialog__buttons" mat-dialog-actions class="button">
    <button mat-button (click)="goToLogin()" class="check-login-dialog__buttons__login">تسجيل الدخول</button>
    <button mat-button (click)="goToRegister()" class="check-login-dialog__buttons__register">انشاء حساب</button>
    <mat-icon
      mat-dialog-close
      aria-hidden="false"
      aria-label="Example close icon"
      class="material-icons-outlined check-login-dialog__buttons__close"
      >close</mat-icon
    >
  </div>
</div>
