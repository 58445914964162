import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { UserService } from "@kortobaa-front/authentication";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private _http: HttpClient, private _user: UserService) {}

  //open notification dialog

  openNotificationDialog = new EventEmitter();

  emitOpenDialog() {
    this.openNotificationDialog.emit();
  }

  getOpenDialog() {
    return this.openNotificationDialog;
  }

  //close notification dialog

  closeNotificationDialog = new EventEmitter();

  emitCloseDialog(body: any) {
    this.closeNotificationDialog.emit(body);
  }

  getCloseDialog() {
    return this.closeNotificationDialog;
  }

  //get new notification

  pushNotification = new EventEmitter();

  getNewNotification() {
    return this.pushNotification;
  }

  emitNewNotficationEvent(body: any) {
    this.pushNotification.emit(body);
  }

  //unseen notifications

  seenNotification = new EventEmitter();

  emitSeenNotification() {
    this.seenNotification.emit();
  }

  getSeenNotification() {
    return this.seenNotification;
  }

  //user is authenticated

  userIsAuthticated = new EventEmitter();

  emitUserIsAuthticated() {
    this.userIsAuthticated.emit();
  }

  getUserIsAuthticated() {
    return this.userIsAuthticated;
  }

  //notification endpoint
  getNotifications() {
    return this._http.get(`${environment.api_url}/my-notifications`, this.userToken());
  }

  getNotificationSeen(skip: number) {
    return this._http.get(
      `${environment.api_url}my-notifications?filter={"where": {"seen": false},"skip":${skip}}`,
      this.userToken()
    );
  }

  getNotificationsScroll(skip: number) {
    return this._http.get(`${environment.api_url}/my-notifications?filter={"skip":${skip}}`, this.userToken());
  }

  getNotificationsCount() {
    return this._http.get(`${environment.api_url}/notifications-counter`, this.userToken());
  }

  updateNotificationToSeen() {
    return this._http.get(`${environment.api_url}/notifications-seen`, this.userToken());
  }

  updateNotification(id: number) {
    return this._http.get(`${environment.api_url}/notification-seen/${id}`, this.userToken());
  }

  userToken() {
    return {
      headers: { Authorization: `Bearer ${this._user.getToken()}` },
      body: {
        platform: "web",
        uuid: "123456789",
      },
    };
  }
}
